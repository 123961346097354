<template>
    <section>
        <div class="row mx-3 bg-white br-t-12 py-2 mt-3 text-general f-18 f-600 px-xl-5 px-lg-5 px-md-5 px-sm-12 px-0 justify-content-xl-start justify-content-lg-start justify-content-md-start justify-content-sm-center justify-content-center ">
            Indice general de cancelación
        </div>
        <div class="row mx-3 bg-white py-3 px-xl-5 px-lg-5 px-md-5 px-sm-12 px-0">
            <div class="col">
                <div class="row mx-0 br-5 border" style="height:30px;background-color:#FFFF;">
                    <el-tooltip placement="top" :content="`Rechazo: ${cancelacionGeneral.rechazos}%`" effect="light">
                        <div v-show="cancelacionGeneral.rechazos > 0" class="bg-general3 h-100 br-tl-5 br-bl-5" :style="`width:${cancelacionGeneral.rechazos}%;`" />
                    </el-tooltip>
                    <el-tooltip placement="top" :content="`Cancelación Tendero: ${cancelacionGeneral.cancelacion_tienda}%`" effect="light">
                        <div v-show="cancelacionGeneral.cancelacion_tienda > 0" class="bg-a9 h-100" :style="`width:${cancelacionGeneral.cancelacion_tienda}%;`" />
                    </el-tooltip>
                    <el-tooltip placement="top" :content="`Cancelación Cliente: ${cancelacionGeneral.cancelacion_cliente}%`" effect="light">
                        <div v-show="cancelacionGeneral.cancelacion_cliente > 0" class="bg-df h-100" :style="`width:${cancelacionGeneral.cancelacion_cliente}%;`" />
                    </el-tooltip>
                    <el-tooltip placement="top" :content="`Cancelación Admin: ${cancelacionGeneral.cancelacion_admin}%`" effect="light">
                        <div v-show="cancelacionGeneral.cancelacion_admin > 0" class="bg-yes h-100 br-tr-5 br-br-5" :style="`width:${cancelacionGeneral.cancelacion_admin}%;`" />
                    </el-tooltip>
                </div>
            </div>
        </div>
        <div class="row mx-3 bg-white br-t-12 py-2 text-general f-16 px-xl-5 px-lg-5 px-md-5 px-sm-12 px-0 justify-content-xl-start justify-content-lg-start justify-content-md-start justify-content-sm-center justify-content-center">
            <div class="col">
                Cancelacion del <b>{{ cancelacionGeneral.cancelaciones }}%</b>
            </div>
            <div class="col ml-auto">
                <b>{{ cancelacionGeneral.total_cancelaciones }}</b> Pedidos cancelados de <b>{{ cancelacionGeneral.total }}</b>  pedidos solicitados
            </div>
        </div>
        <div class="row mx-3 bg-white br-t-12 py-2 mt-3 text-general f-18 f-600 px-xl-5 px-lg-5 px-md-5 px-sm-12 px-0 justify-content-xl-start justify-content-lg-start justify-content-md-start justify-content-sm-center justify-content-center ">
            Cancelación
        </div>
        <div class="row mx-3 bg-white py-3 px-xl-5 px-lg-5 px-md-5 px-sm-12 px-0">
            <div class="col">
                <div class="row mx-0 br-5" style="height:30px;background-color:#EBEBEB;">
                    <el-tooltip placement="top" :content="`Rechazo: ${porcentajes.rechazos}%`" effect="light">
                        <div v-show="porcentajes.rechazos > 0" class="bg-general3 h-100 br-tl-5 br-bl-5" :style="`width:${porcentajes.rechazos}%;`" />
                    </el-tooltip>
                    <el-tooltip placement="top" :content="`Cancelación Tendero: ${porcentajes.cancelacion_tienda}%`" effect="light">
                        <div v-show="porcentajes.cancelacion_tienda > 0" class="bg-a9 h-100" :style="`width:${porcentajes.cancelacion_tienda}%;`" />
                    </el-tooltip>
                    <el-tooltip placement="top" :content="`Cancelación Cliente: ${porcentajes.cancelacion_cliente}%`" effect="light">
                        <div v-show="porcentajes.cancelacion_cliente > 0" class="bg-df h-100" :style="`width:${porcentajes.cancelacion_cliente}%;`" />
                    </el-tooltip>
                    <el-tooltip placement="top" :content="`Cancelación Admin: ${porcentajes.cancelacion_admin}%`" effect="light">
                        <div v-show="porcentajes.cancelacion_admin > 0" class="bg-yes h-100 br-tr-5 br-br-5" :style="`width:${porcentajes.cancelacion_admin}%;`" />
                    </el-tooltip>
                </div>
            </div>
        </div>
        <div class="row mx-3 py-3 bg-white br-b-12 mb-5 justify-center">
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mt-auto">
                <echart v-if="optionRechazado.series.length" :options="optionRechazado" />
                <div v-else class="">
                    <div class="position-relative pt-3 f-20 text-center text-general">
                        <p>Rechazado</p>
                        <p>0%</p>
                    </div>
                    <div class="position-relative f-20 text-center">
                        <img src="/img/otros/cilueta_torta.png" alt="" />
                        <div class="position-absolute w-100 h-100" style="background: #ffffffcf;top:0 " />
                    </div>
                </div>
                <el-popover
                v-model="visibleRechazado"
                popper-class="popoverEdit"
                placement="top"
                trigger="manual"
                >
                    <div class="row justify-center">
                        Rechazado
                    </div>
                    <div v-for="(rechazo, r) in rechazos" :key="r" class="row mx-2 justify-content-center py-2">
                        <div style="width:14px;height:14px;border-radius:50%;" :style="`backgroundColor:${rechazo.color}`" />
                        <div class="col">
                            {{ rechazo.name }}
                        </div>
                        <div class="col-auto">
                            {{ agregarSeparadoresNumero(rechazo.porcentaje,1) }}%
                        </div>
                    </div>
                    <div v-if="rechazos.length" slot="reference" class="row justify-center text-muted2 f-15 cr-pointer" @click="visibleRechazado = !visibleRechazado">
                        Ver más <i class="icon-angle-up text-orange f-15 cr-pointer" />
                    </div>
                </el-popover>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                <echart v-if="optionAdminCancelacion.series.length" :options="optionAdminCancelacion" />
                <div v-else class="">
                    <div class="position-relative pt-3 f-20 text-center" style="color:#A9A9A9">
                        <p>Cancelación de Administrador</p>
                        <p>0%</p>
                    </div>
                    <div class="position-relative f-20 text-center">
                        <img src="/img/otros/cilueta_torta.png" alt="" />
                        <div class="position-absolute w-100 h-100" style="background: #ffffffcf;top:0 " />
                    </div>
                </div>
                <el-popover
                v-model="visibleAdminCancelacion"
                popper-class="popoverEdit"
                placement="top"
                trigger="manual"
                >
                    <div class="row justify-center">
                        Cancelación Administrador
                    </div>
                    <div v-for="(admin, r) in cancelacionAdmin" :key="r" class="row mx-2 justify-content-center py-2">
                        <div style="width:14px;height:14px;border-radius:50%;" :style="`backgroundColor:${admin.color}`" />
                        <div class="col">
                            {{ admin.name }}
                        </div>
                        <div class="col-auto">
                            {{ agregarSeparadoresNumero(admin.porcentaje,1) }}%
                        </div>
                    </div>
                    <div v-if="cancelacionAdmin.length" slot="reference" class="row justify-center text-muted2 f-15 cr-pointer" @click="visibleAdminCancelacion = !visibleAdminCancelacion">
                        Ver más <i class="icon-angle-up text-orange f-15 cr-pointer" />
                    </div>
                </el-popover>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                <echart v-if="optionTenderoCancelacion.series.length" :options="optionTenderoCancelacion" />
                <div v-else class="">
                    <div class="position-relative pt-3 f-20 text-center" style="color:#A9A9A9">
                        <p>Cancelación de {{ $config.vendedor }}</p>
                        <p>0%</p>
                    </div>
                    <div class="position-relative f-20 text-center">
                        <img src="/img/otros/cilueta_torta.png" alt="" />
                        <div class="position-absolute w-100 h-100" style="background: #ffffffcf;top:0 " />
                    </div>
                </div>
                <el-popover
                v-model="visibleTenderoCancelacion"
                popper-class="popoverEdit"
                placement="top"
                trigger="manual"
                >
                    <div class="row justify-center">
                        Cancelación {{ $config.vendedor }}
                    </div>
                    <div v-for="(tienda, r) in cancelacionTienda" :key="r" class="row mx-2 justify-content-center py-2">
                        <div style="width:14px;height:14px;border-radius:50%;" :style="`backgroundColor:${tienda.color}`" />
                        <div class="col">
                            {{ tienda.name }}
                        </div>
                        <div class="col-auto">
                            {{ agregarSeparadoresNumero(tienda.porcentaje,1) }}%
                        </div>
                    </div>
                    <div v-if="cancelacionTienda.length" slot="reference" class="row justify-center text-muted2 f-15 cr-pointer" @click="visibleTenderoCancelacion = !visibleTenderoCancelacion">
                        Ver más <i class="icon-angle-up text-orange f-15 cr-pointer" />
                    </div>
                </el-popover>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                <echart v-if="optionClienteCancelacion.series.length" :options="optionClienteCancelacion" />
                <div v-else class="">
                    <div class="position-relative pt-3 f-20 text-center" style="color:#DFDFDF">
                        <p>Cancelación {{ $config.cliente }}</p>
                        <p>0%</p>
                    </div>
                    <div class="position-relative f-20 text-center">
                        <img src="/img/otros/cilueta_torta.png" alt="" />
                        <div class="position-absolute w-100 h-100" style="background: #ffffffcf;top:0 " />
                    </div>
                </div>
                <el-popover
                v-model="visibleClienteCancelacion"
                popper-class="popoverEdit"
                placement="top"
                trigger="manual"
                >
                    <div class="row justify-center">
                        Cancelación {{ $config.cliente }}
                    </div>
                    <div v-for="(cliente, r) in cancelacionCliente" :key="r" class="row mx-0 justify-content-center py-2">
                        <div style="width:14px;height:14px;border-radius:50%;" :style="`backgroundColor:${cliente.color}`" />
                        <div class="col">
                            {{ cliente.name }}
                        </div>
                        <div class="col-auto">
                            {{ agregarSeparadoresNumero(cliente.porcentaje,1) }}%
                        </div>
                    </div>
                    <div v-if="cancelacionCliente.length" slot="reference" class="row justify-center text-muted2 f-15 cr-pointer" @click="visibleClienteCancelacion = !visibleClienteCancelacion">
                        Ver más <i class="icon-angle-up text-orange f-15 cr-pointer" />
                    </div>
                </el-popover>
            </div>
        </div>
    </section>
</template>
<script>
import Vue from 'vue'
import echarts from 'echarts'
Vue.component('echarts',echarts);
/* -------- */
let data = [

    {
        value: 410,
        name: 'Muy ocupado'
    },
    {
        value: 380,
        name: 'Voy de salida'
    },
    {
        value: 501,
        name: 'Automatico'
    },
    {
        value: 600,
        name: 'Error'
    },
    {
        value: 540,
        name: 'Mal Cliente'
    },
    {
        value: 800,
        name: 'Nuevo Pedido'
    }
];
let color = [ '#A78FE2', '#F1BC8D', '#72DCCF', '#F1E1AB', '#8592A4', '#71C1EC', '#D382C7', '#79BB88', '#9FDDF8', '#F171D8', '#7797F1', '#DC7279', '#B78471', '#76A0B8', '#87DC72', ];
let total = data[0].value + data[1].value + data[2].value + data[3].value + data[4].value;
let baseData = [];
for (var i = 0; i < data.length; i++){
    baseData.push({
        value: data[i].value,
        name: data[i].name,
        itemStyle: {
            normal: {
                borderWidth: 22,
                borderColor: color[i]
            }
        },

    });
}
/* ---------- */

export default {
    props: {
        rechazos : {
            type: Array,
            default: () => []
        },
        cancelacionTienda : {
            type: Array,
            default: () => []
        },
        cancelacionCliente : {
            type: Array,
            default: () => []
        },
        cancelacionAdmin : {
            type: Array,
            default: () => []
        },
        porcentajes : {
            type: Object,
            default: () => {
                return {
                    rechazos: 0,
                    cancelacion_tienda: 0,
                    cancelacion_cliente: 0,
                    cancelacion_admin: 0
                }
            }
        },
        cancelacionGeneral : {
            type: Object,
            default: () => {
                return {
                    rechazos: 0,
                    cancelacion_tienda: 0,
                    cancelacion_cliente: 0,
                    cancelacion_admin: 0
                }
            }
        },
    },
    data(){
        return{
            visibleRechazado: false,
            visibleTimeOut: false,
            visibleTenderoCancelacion: false,
            visibleClienteCancelacion: false,
            visibleAdminCancelacion: false,
            optionRechazado :{
                title: {
                    text: 'Rechazado',
                    subtext: `${this.agregarSeparadoresNumero(this.porcentajes.rechazos)}%`,
                    textStyle: {
                        color: '#000000',
                        fontSize: 20,
                        fontWeight: 'normal'
                    },
                    subtextStyle: {
                        fontSize: 20,
                        color: '#000000',
                        fontWeight: 'bold'
                    },
                    left: "center",
                    top: 20,
                },
                backgroundColor: 'white',
                color: color,
                series: [
                    {
                        zlevel: 1,
                        name: 'Rechazado',
                        type: 'pie',
                        top: 'bottom',
                        bottom: '-78%',
                        left: 'center',
                        width: 'auto',
                        height: 'auto',
                        radius: [85, 90],
                        startAngle: '120',
                        emphasis: {
                            label: {
                                formatter: param => {
                                    return `${param.name} \n ${this.agregarSeparadoresNumero(param.percent)}%`
                                },
                                show: true,
                                fontSize: '15',
                            }
                        },
                        hoverAnimation: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        data: this.rechazos,
                    },
                ]
            },
            optionTenderoCancelacion :{
                title: {
                    text: 'Cancelacion Tendero',
                    subtext: `${this.agregarSeparadoresNumero(this.porcentajes.cancelacion_tienda)}%`,
                    textStyle: {
                        color: '#000000',
                        fontSize: 20,
                        fontWeight: 'normal'
                    },
                    subtextStyle: {
                        fontSize: 20,
                        color: '#000000',
                        fontWeight: 'bold'
                    },
                    left: "center",
                    top: 20,
                },
                backgroundColor: 'white',
                color: color,
                series: [
                    {
                        zlevel: 1,
                        name: 'Rechazado',
                        type: 'pie',
                        top: 'bottom',
                        bottom: '-78%',
                        left: 'center',
                        width: 'auto',
                        height: 'auto',
                        radius: [85, 90],
                        startAngle: '120',
                        emphasis: {
                            label: {
                                formatter: param => {
                                    return `${param.name} \n ${this.agregarSeparadoresNumero(param.percent)}%`
                                },
                                show: true,
                                fontSize: '15',
                            }
                        },
                        hoverAnimation: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        data: this.cancelacionTienda
                    },
                ]
            },
            optionClienteCancelacion :{
                title: {
                    text: 'Cancelacion cliente',
                    subtext: `${this.agregarSeparadoresNumero(this.porcentajes.cancelacion_cliente)}%`,
                    textStyle: {
                        color: '#000000',
                        fontSize: 20,
                        fontWeight: 'normal'
                    },
                    subtextStyle: {
                        fontSize: 20,
                        color: '#000000',
                        fontWeight: 'bold'
                    },
                    left: "center",
                    top: 20,
                },
                backgroundColor: 'white',
                color: color,
                series: [
                    {
                        zlevel: 1,
                        name: 'Rechazado',
                        type: 'pie',
                        top: 'bottom',
                        bottom: '-78%',
                        left: 'center',
                        width: 'auto',
                        height: 'auto',
                        radius: [85, 90],
                        startAngle: '120',
                        emphasis: {
                            label: {
                                formatter: param => {
                                    return `${param.name} \n ${this.agregarSeparadoresNumero(param.percent)}%`
                                },
                                show: true,
                                fontSize: '15',
                            }
                        },
                        hoverAnimation: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        data: this.cancelacionCliente,
                    },
                ]
            },
            optionAdminCancelacion :{
                title: {
                    text: 'Cancelacion Admin',
                    subtext: `${this.agregarSeparadoresNumero(this.porcentajes.cancelacion_admin)}%`,
                    textStyle: {
                        color: '#000000',
                        fontSize: 20,
                        fontWeight: 'normal'
                    },
                    subtextStyle: {
                        fontSize: 20,
                        color: '#000000',
                        fontWeight: 'bold'
                    },
                    left: "center",
                    top: 20,
                },
                backgroundColor: 'white',
                color: color,
                series: [
                    {
                        zlevel: 1,
                        name: 'Rechazado',
                        type: 'pie',
                        top: 'bottom',
                        bottom: '-78%',
                        left: 'center',
                        width: 'auto',
                        height: 'auto',
                        radius: [85, 90],
                        startAngle: '120',
                        emphasis: {
                            label: {
                                formatter: param => {
                                    return `${param.name} \n ${this.agregarSeparadoresNumero(param.percent)}%`
                                },
                                show: true,
                                fontSize: '15',
                            }
                        },
                        hoverAnimation: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        data: this.cancelacionAdmin,
                    },
                ]
            }
        }
    },
    watch: {
        rechazos(val){
            this.optionRechazado.series[0].data = val
        },
        cancelacionTienda(val){
            this.optionTenderoCancelacion.series[0].data = val
        },
        cancelacionCliente(val){
            this.optionClienteCancelacion.series[0].data = val
        },
        cancelacionAdmin(val){
            this.optionAdminCancelacion.series[0].data = val
        },
        porcentajes(val){
            this.optionRechazado.title.subtext = `${this.agregarSeparadoresNumero(val.rechazos)}%`
            this.optionTenderoCancelacion.title.subtext = `${this.agregarSeparadoresNumero(val.cancelacion_tienda)}%`
            this.optionClienteCancelacion.title.subtext = `${this.agregarSeparadoresNumero(val.cancelacion_cliente)}%`
            this.optionAdminCancelacion.title.subtext = `${this.agregarSeparadoresNumero(val.cancelacion_admin)}%`
        },

    },
    mounted(){
        this.optionTenderoCancelacion.title.text = `Cancelacion ${this.$config.vendedor}`
        this.optionClienteCancelacion.title.text = `Cancelacion ${this.$config.cliente}`
    },
}
</script>
<style lang="scss">
.popoverEdit{
    border: 1px solid #03D6BC;
    border-radius: 12px;;
}
.bg-a9{
    background-color: #5e5b5b;
}
.bg-df{
    background-color: #a39c9c;;
}
.bg-yes{
    background-color: #cbcaca;
}
</style>
